import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import website from '../../config/website'
import SliceZone from '../components/SliceZone'

export const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const activeDoc = {
    lang,
    type,
    url,
  }
  const topMenu = data.prismicTopMenu || {}

  return (
    <>
      <SEO
        title={`${pageContent.data.display_title.text} | ${website.titleAlt}`}
        pathname={typeof window === 'undefined' ? '' : location.pathname}
        desc={pageContent.data.social_description_override || data.description}
        banner={pageContent.data.social_image_override.url}
        node={pageContent}
      />
      <SliceZone slices={page.body} />
    </>
  )
}

export const query = graphql`
  query pageQuery($uid: String, $lang: String) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
        url
      }
      data {
        display_title {
          text
        }
        social_description_override
        social_image_override {
          url
        }
        body {
          ... on PrismicPageBodyInfoWithImage {
            id
            slice_label
            slice_type
            primary {
              featured_image {
                alt
                url
                fixed(imgixParams: { w: 400, h: 400 }) {
                  src
                }
              }
              section_title {
                html
                raw
                text
              }
              text {
                html
                raw
                text
              }
              url_hash {
                raw
              }
              theme_colour
              text_position
            }
          }
          ... on PrismicPageBodyTextInfo {
            id
            slice_label
            slice_type
            primary {
              left_column_text {
                html
                raw
                text
              }
              right_column_text {
                html
                raw
                text
              }
              section_title {
                html
                raw
                text
              }
              url_hash {
                raw
              }
              theme_colour
            }
          }
          ... on PrismicPageBodyGeneralContent {
            id
            slice_label
            slice_type
            primary {
              theme_colour
              content {
                html
                raw
                text
              }
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyBlockquote {
            id
            slice_label
            slice_type
            primary {
              theme_colour
              text {
                html
                raw
                text
              }
              cite {
                html
                raw
                text
              }
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyVideoEmbed {
            id
            slice_label
            slice_type
            primary {
              video {
                embed_url
                html
              }
              video_title {
                html
                raw
                text
              }
              video_description {
                html
                raw
                text
              }
              theme_colour
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyDna {
            id
            slice_label
            slice_type
            primary {
              title {
                raw
              }
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyHowWeDoIt {
            id
            slice_label
            slice_type
            primary {
              title {
                raw
              }
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyLogoList {
            id
            slice_label
            slice_type
            primary {
              url_hash {
                raw
              }
              title {
                html
                raw
                text
              }
            }
            items {
              background
              logo {
                alt
                url
                fluid(
                  imgixParams: {
                    maxWidth: 300
                    w: 300
                    width: 300
                    fit: "crop"
                    auto: "format"
                  }
                ) {
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicPageBodyHeroImageWithText {
            id
            slice_label
            slice_type
            primary {
              title {
                raw
              }
              image {
                alt
                url
                fluid(
                  imgixParams: {
                    maxWidth: 300
                    w: 300
                    width: 300
                    fit: "crop"
                    auto: "format"
                  }
                ) {
                  src
                  srcSet
                }
              }
              content {
                html
                raw
                text
              }
              content_right {
                raw
                html
                text
              }
              url_hash {
                raw
              }
            }
          }
          ... on PrismicPageBodyImageWall {
            id
            slice_label
            slice_type
            primary {
              title {
                text
                raw
              }
              url_hash {
                raw
              }
            }
            items {
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
                fluid(
                  imgixParams: {
                    maxWidth: 300
                    w: 300
                    width: 300
                    fit: "crop"
                    auto: "format"
                  }
                ) {
                  src
                  srcSet
                }
              }
              tile_size
            }
          }
          ... on PrismicPageBodyTwoColumnText {
            id
            slice_label
            slice_type
            primary {
              theme_colour
              title {
                html
                raw
                text
              }
              url_hash {
                raw
              }
            }
            items {
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyFullWidthImage {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                fluid {
                  src
                  srcSet
                }
              }
              image_title {
                html
                raw
                text
              }
              image_description {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
  }
`

export default withPreview(PageTemplate)
